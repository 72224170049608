<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    class="csn-rating-full-icon"
  >
    <polygon
      fill-rule="evenodd"
      points="12 18.27 18.18 22 16.54 14.97 22 10.24 14.81 9.62 12 3 9.19 9.62 2 10.24 7.45 14.97 5.82 22"
    />
  </svg>
</template>
<script>
import { RATING_FULL_ICON } from '@/constants'

export default {
  name: RATING_FULL_ICON,
}
</script>
